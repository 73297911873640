@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*  sans-serif;*/
  font-family: "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*Added to body during modal popup, so that no scroll bar appears on body*/
.dontOverflow {
  overflow: hidden
}

.penis {
  min-height: calc(100vh - 184px);
}

.image-placeholder {
  background-color: #f6f6f6;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.image-placeholder img {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s linear;
}

.image-placeholder img.loaded {
  opacity: 1;
}

.image-small {
  filter: blur(5px);
  transform: scale(1);
}

header h4 {
  font-family: "Brush Script MT";
}
.jumbotron {
  padding: 2rem !important;
}

.cardTitle {
  font-family: "Brush Script MT";
}

/*Stuff for Modal */
.hidden {
  visibility: hidden;
}
/*.hiddenModal * .modalImage {*/
/*  opacity: 0;*/
/*}*/
.myModal {
  z-index: 1;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.99) !important;
  margin-right: 0;
}
.modalImageContainer {
  margin-right: 0px;
  transition: margin-right 0.5s ease-in-out;
}

.mover {
  transition: all 0.5s;
}

.modalImageContainer.showDetails {
  margin-right: 24em;
}

.modalImage{
  width: 1920px;
  height: 1080px;
  object-fit: contain;
}

.modalLeft, .modalRight{
  width: 50%;
  background-color: black;
}

.previous{
  position: relative;
  top: 50%;
  right: 15%;
}
.next {
  position: relative;
  top: 50%;
  left: 15%;
}
.detailsButton {
  position: relative;
  background-color: black;
}
.detailsButton i {
  color: white;
  cursor: pointer;
}
#modalDetails i {
  color: black;
}
.photoModalDetails {
  background-color: white;
  width: 100%;
  position:absolute;
  top:-900px;
  z-index: -50;
  transition: top 750ms;
  transition-timing-function: ease-in-out;
}
.slideDown {
  top:100%;
}

.col-lg-4 {
  padding-bottom: 15px;
}

/* .tag-searcher {
  position: absolute;
  z-index: 1000;
} */

.list-group-item:hover {
  background-color: grey;
}

#my-map {
  margin: auto;
  margin-top: 15px;
}
.mapContainer {
  height: 300px;
  width: 100%;
  min-width: 300px;
}

.tag-searcher {
  max-height: 400px;
  overflow: auto;
}

@media only screen and (max-width: 1240px) {
  .modalRight, .modalLeft { 
    width: 15%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    top: 0;
  }
  .modalRight {
    right: 0;
  }
  .modalLeft {
    left: 0;
  }
}

@media only screen and (min-width: 600px) {
  .modalRight:hover {
    background-image: linear-gradient(to right, rgba(81,87,85,0), rgba(81,87,85,.8));
  }
  .modalLeft:hover {
    background-image: linear-gradient(to left, rgba(81,87,85,0), rgba(81,87,85,.8));
  }
}

@media only screen and (min-width: 641px) {
  .modalDetails {
    width: 24em;
    transition: right 0.5s ease-in-out;
    right: -24em;
    top: 0;
  }

  .modalDetails.showDetails {
    right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .modalDetails {
    width: 100%;
    transition: bottom 0.2s ease-in-out;
    bottom: -100%;
  }

  .modalDetails.showDetails {
    width: 100%;
    bottom: 0;
  }
}

/* @media only screen and (max-height: 1000px) {
  .modalImage{
    width: 100%;
    height: auto;
  }
} */

/* @media only screen and (max-height: 484px) {
  .modalImage{
    height: auto;
    width: 100%;
  }
} */